import React from "react"
import styled, { css } from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { navigate } from "gatsby" //import navigate from gatsby

import globals from "./globals"
import { Container, ContentBox, H2, P } from "./styledcomponents"

import LeaningImage from "./leaningImage"
import Button from "./button"
import Icons from "./icons"

const subColor = globals.color.darkShades
const mainColor = "#666"

const Icon = styled(Icons)`
  background-color: ${globals.color.main}50;
  height: 1.3em;
  margin-right: 5px;
  float: left;
`

const shrinkLabelStyles = css`
  ${(props) =>
    props.name !== "privacy"
      ? `font-size: 12px;  color: ${mainColor};  left: 10px; top: -14px;`
      : undefined}
`

const Form = styled.form`
  display: grid;
  grid-template-columns: ${(props) =>
    props.gridTemplateColumns ? props.gridTemplateColumns : "1fr"};
  grid-gap: 1em 2em;
  width: 100%;
  max-width: ${(props) =>
    props.maxWidth ? props.maxWidth : globals.media.tablet};
  ${(props) => props.alignItems && `align-items: ${props.alignItems}`};
  ${(props) => props.justifyItems && `justify-items: ${props.justifyItems}`};
  @media (max-width: ${globals.media.tablet}) {
    ${(props) =>
    props.gridTemplateColumnsTablet &&
    `grid-template-columns: ${props.gridTemplateColumnsTablet}`};
  }

  @media (max-width: ${globals.media.mobile}) {
    grid-template-columns: 1fr;
  }
`

const GroupContainer = styled.div`
  position: relative;
  padding: 0 10px;
  margin: 0;
  text-align: left;
  ${(props) => props.fullWidth && "grid-column: 1/3"};
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  input[type="checkbox"] {
    position: absolute;
    left: -360%;
    height: 2em;
    width: 400%;
  }

  @media (max-width: ${globals.media.tablet}) {
    ${(props) => props.columnsTablet && `grid-column: ${props.columnsTablet}`};
  }
  @media (max-width: ${globals.media.mobile}) {
    ${(props) => props.fullWidth && "grid-column: 1/2"};
  }
  ${(props) => props.columns && `grid-column: ${props.columns}`}
`

const FormInputContainer = styled.input`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;

  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${(props) => (props.type === "checkbox" ? undefined : shrinkLabelStyles)}
  }
`

const FormTextareaContainer = styled.textarea`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px;
  display: block;
  width: calc(100% - 20px);
  border: none;
  border-radius: 0;
  background-color: ${globals.color.main}50;
  margin: 0;
  height: 7em;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`

const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  ${(props) => props.type !== "checkbox" && "position: absolute"};
  pointer-events: none;
  left: ${(props) => (props.type !== "checkbox" ? "20px" : "50px")};
  top: 10px;
  text-align: left;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`

const FormH = styled.p`
  color: ${subColor};
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  pointer-events: none;
  padding-left: 10px;
  margin-bottom: -10px;
`

const GridLeaningImage = styled(LeaningImage)``

const DownloadButton = styled(Button)`
  @media (max-width: ${globals.media.tablet}) {
    grid-row-start: 6;
  }
`

const FormInput = ({ handleChange, label, type, name, ...props }) => (
  <GroupContainer
    fullWidth={props.fullWidth}
    columns={props.columns}
    columnsTablet={props.columnsTablet}
  >
    {type === "textarea" ? (
      <FormTextareaContainer
        onChange={handleChange}
        name={name}
        id={name}
        {...props}
      />
    ) : (
      <FormInputContainer
        onChange={handleChange}
        type={type}
        name={name}
        id={name}
        {...props}
      />
    )}
    {type === "checkbox" ? (
      <Icon icon={props.value ? "squareCheck" : "square"} />
    ) : undefined}

    {label ? (
      <FormInputLabel
        className={type !== "checkbox" && props.value.length ? "shrink" : ""}
        type={type}
        for={name}
      >
        {label}
      </FormInputLabel>
    ) : null}
  </GroupContainer>
)

export class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      result: "",
      ma_local_time: new Date().getTimezoneOffset(),
      ma_email: "",
      ma_fullname: "",
      ma_phone: "",
      ma_interests: props.referral ? props.referral : "",
      headline: props.headline,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    addToMailchimp(this.state.ma_email, {
      FNAME: this.state.ma_fullname,
      LNAME: this.state.ma_fullname,
      PHONE: this.state.ma_phone,
      FINDINGUS: this.state.ma_interests,
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        console.log(data)
      })
      .catch((data) => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        console.log("FAIL")
        console.log(data)
      })
  }

  handleChange = (event) => {
    const { value, name } = event.target
    this.setState({ [name]: value })
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  calcInterestGroup = () => {
    const yogaConst = 4
    const maKidsConst = 8
    const maConst = 16
    let activity = 0

    if (this.state.activityYoga) activity += yogaConst
    if (this.state.activityMaKids) activity += maKidsConst
    if (this.state.activityMa) activity += maConst

    return activity
  }

  render() {
    return (
      <Container maxWidth="10px" backgroundColor="white" margin="2em 0">
        <H2>{this.state.headline}</H2>
        {this.state.result ?
          <ContentBox
            backgroundColor={globals.color.lightShades}
            padding="1em"
          >
            <P>{this.state.result}</P><
              a onClick={() => this.setState({ result: "" })}>zurück</a>
          </ContentBox>
          :
          <Form
            name="contact"
            method="POST"
            id="form"
            action="https://services.martialytics.com/widgets/leads"
            data-netlify-recaptcha="true"
            data-netlify="true"
            onSubmit={this.handleSubmit}
          >
            <FormInput
              name="ma_fullname"
              type="text"
              value={this.state.ma_fullname}
              onChange={this.handleChange}
              label="Name"
              required
            />
            <FormInput
              name="ma_email"
              type="email"
              value={this.state.ma_email}
              onChange={this.handleChange}
              label="Email"
              id="ma_email"
              required
            />
            <FormInput
              name="ma_phone"
              type="tel"
              value={this.state.ma_phone}
              onChange={this.handleChange}
              label="Telefonnummer"
              id="ma_phone"
              required
            />
            <Button center type="submit">
              Abschicken
            </Button>
          </ Form >
        }
      </Container>
    )
  }
}
